import i18n from "@/plugins/i18n";

export const columns = [
  {
    label: 'id',
    field: 'id',
    width: '5%',
    filterOptions: {
      enabled: false,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'name',
    field: 'name',
    width: '35%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'token',
    field: 'token',
    width: '30%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'status',
    field: 'status',
    width: '25%',
    formatFn: (value) => {
      if (value === 1) {
        return i18n.t('Active');
      }
      if (value === 0) {
        return i18n.t('Hidden');
      }
      return '';
    },
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: '',
    field: 'actions',
    width: '10%',
    sortable: false,
    actions: [
      {
        label: '',
        class: 'fa fa-sort',
        action: {
          type: 'route',
          name: 'SortFilterTagItems',
          paramsResolver: (data) => {
            return {filterTagItemRelationId: data.filterRelation.id}
          },
        },
      },
    ]
  },
]
