<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <h2>{{ $t('tagItemsFiltersList') }}</h2>
      <grid-view-table
        :id="'tag-item-filter'"
        :columns="columns"
        :path="'tag-item-filters/' + tagItemId"
        :update-route="'UpdateFilter'"
        :add-text="$t('addFilter')"
        :add-route="'AddTagItemFilter'"
        :add-link-params="addLinkParams"
      />
    </div>
  </div>
</template>

<script>
  import GridViewTable from '../../components/GridViewTable';
  import { columns } from './grid-views/tag-items';


  export default {
    name: 'TagItemsFiltersList',
    components: {
      GridViewTable,
    },
    props: ['tagItemId'],
    watch: {
      type: {
        immediate: true,
        deep: true,
        handler() {
          setTimeout(() => {
            this.$refs.tree.loadItems();
          }, 300)
        }
      }
    },
    data: () => {
      return {
        columns,
      }
    },
    computed: {
      addLinkParams: function () {
        return {tagItem: this.tagItemId}
      },
    },
  }
</script>
